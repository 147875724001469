import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <div
      style={{
        position: `absolute`,
        top: `500px`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
      }}
    >
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `space-around`,
          width: `140px`,
          height: `50px`,
          background: `#1d4c86`,
        }}
      ></div>
      <div
        style={{
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `space-around`,
          width: `140px`,
          height: `50px`,
          background: `#1d8086`,
        }}
      ></div>
    </div>
    <p>
      Docunext is a blog by me, Albert Lash, featuring a collection of technical
      notes I have collected over a decade of working with mostly open source,
      networking and server software.
    </p>
  </Layout>
)